import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { format, isToday } from 'date-fns';
import './ResultsPage.css';

const ResultsPage = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [mrp, setMrp] = useState("2");

  // Get API base URL from environment variable
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Use useCallback to memoize the fetchData function
  const fetchData = useCallback(async (date) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${API_BASE_URL}/components_data/bydate_result_website/${date}`
      );
      setResults(response.data.Results || []);
    } catch (err) {
      setError('Error fetching data. Please try again later.');
      console.error('API Error:', err);
    } finally {
      setLoading(false);
    }
  }, [API_BASE_URL]);

  // Now include fetchData in the dependency array
  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate, fetchData]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleMrpChange = (e) => {
    setMrp(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData(selectedDate);
  };

  // Format time from 24h to 12h format
  const formatTime = (timeStr) => {
    if (!timeStr) return '--';
    
    const [hours, minutes] = timeStr.split(':');
    let h = parseInt(hours, 10);
    const ampm = h >= 12 ? 'pm' : 'am';
    h = h % 12;
    h = h ? h : 12; // the hour '0' should be '12'
    return `${h}:${minutes} ${ampm}`;
  };

  useEffect(() => {
    // Highlight current time slot
    const highlightCurrentTimeSlot = () => {
      const now = new Date();
      const currentHour = now.getHours().toString().padStart(2, '0');
      const currentMinute = now.getMinutes().toString().padStart(2, '0');
      const currentTime = `${currentHour}:${currentMinute}:00`;
      
      const timeElements = document.querySelectorAll('.result-time');
      timeElements.forEach(el => {
        if (el.textContent.trim() === currentTime && isToday(new Date(selectedDate))) {
          el.parentElement.style.backgroundColor = '#e6f7ff';
          el.parentElement.style.boxShadow = '0 0 5px rgba(0,123,255,0.5)';
        }
      });
    };
    
    if (!loading) {
      highlightCurrentTimeSlot();
    }
  }, [loading, selectedDate]);

  return (
    <div className="container text-center">
      <br />
      <form onSubmit={handleSubmit}>
        <div className="container">
          <h6>2DIGIT Result</h6>
          <div>
            <input 
              type="date" 
              name="ddate" 
              id="ddate" 
              value={selectedDate}
              onChange={handleDateChange}
            />
            <select 
              id="mrp" 
              name="mrp" 
              value={mrp}
              onChange={handleMrpChange}
            >
              <option value="2">MRP. 2</option>
            </select>
            <input type="submit" id="view" value="View" />
          </div>
          
          {loading && <div className="loading">Loading...</div>}
          
          {error && <div className="error">{error}</div>}

          {!loading && !error && (
            <table className="table table-striped table-bordered">
              <thead>
                <tr align="left">
                  <th>TIME</th>
                  <th>RESULT</th>
                  <th>BONUS</th>
                </tr>
              </thead>
              <tbody>
                {results.map((result, index) => (
                  <tr key={index}>
                    <td>{formatTime(result.r_time)}</td>
                    <td>{result.number}</td>
                    <td>{result.prize_multiplier === 1 ? "" : `${result.prize_multiplier} X`}</td>
                  </tr>
                ))}
                {/* Add an empty row at the end */}
                <tr>
                  <td>--</td>
                  <td>--</td>
                  <td>--</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </form>
    </div>
  );
};

export default ResultsPage;
