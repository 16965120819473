import React from 'react';
import logo from '../assets/logo.png';

const HomePage = () => {
  return (
    <div className="container">
      <div className="logo-container">
        <h1>WELCOME TO JACKPOT GAME</h1>
        <img src={logo} alt="Jackpot Logo" className="logo" />
      </div>
    </div>
  );
};

export default HomePage;
